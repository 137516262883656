import React, { FC, useContext, useEffect, useState } from 'react';
import { ContentContext, FormValuesContext } from '../../../../OffersSearchForm/index.contexts';
import { SearchMobileProps } from './SearchMobile.types';
import SearchMobileModalLazy from './SearchMobileModalLazy';
import { StyledSearchMobile } from './SearchMobile.styles';
import { CALENDAR_STATE, DESTINATION_STATE } from '../../../../OffersSearchForm/index.constants';
import { DATES, DESTINATION, DESTINATION_HIDDEN_FIELDS } from '../../../../OffersSearchForm/constants/StoreConstants';
import { getMPOFormattedDate, DateObjectType } from '../../../../OffersSearchForm/utils/DateUtils';
import { getUrlParam } from '../../../../OffersSearchForm/utils/OfferUtils';
import config from '../../../../../lib/config';
import { setDestination } from '../../../../OffersSearchForm/store/store.actions';
import { eventUtil } from '@marriott/mi-ui-library-shop';
import moment from 'moment';
import { Text, Types } from '@marriott/mi-ui-library';

const SearchMobile: FC<SearchMobileProps> = ({
  className,
  isHideDestinationProps = false,
  isPublishUserSelection = false,
  isHideFlexibleDates = false,
  isMPO = false,
  isSPO = false,
  previewMode,
  acceptLanguage,
  colorScheme,
}) => {
  const { mobilePlaceholderText, dateFormat } = useContext(ContentContext);
  // const dateFormat = getDateFormat(acceptLanguage ?? '');

  // to get destination name and get it fixed on mobile view for SPO
  const { UUID, formValues, setFormValues } = useContext(FormValuesContext);
  const { title, dates, destination } = useContext(ContentContext);
  const propertyName = formValues?.[DESTINATION]?.displayText;
  const { fromDate, toDate } = formValues?.[DATES] ?? {};
  // to get the dates based on the localization
  const getLocalizedDate = (date: DateObjectType | undefined): string => {
    moment.locale('locale');
    if (date) {
      //initially date will be in the english format we need to change that to the localized value
      const formattedDate = moment(date).format();
      return moment(formattedDate).format(dateFormat);
    } else {
      return '';
    }
  };
  let displayTextDate = '';
  if (fromDate || toDate) {
    displayTextDate = `${getLocalizedDate(fromDate)}${toDate ? ` - ${getLocalizedDate(toDate)}` : ''}`;
  }
  const destinationText = formValues?.[DESTINATION]?.displayText;
  const hiddenFieldsValues = formValues?.[DESTINATION_HIDDEN_FIELDS];
  /** Add dates value computation */
  // const { localDateFormat } = useContext(ContentContext);
  // const dateFormat = (localDateFormat as string) ?? DATE_FORMAT_VALUE;
  // const fromDateValue = getValueFromDate(fromDate, dateFormat);
  // const toDateValue = getValueFromDate(toDate, dateFormat);
  // const fromToDateValue = toDateValue;
  //numberInParty is total number of persons.
  const childCount = formValues?.roomsAndGuests?.childrenCount;
  const numberInParty =
    (formValues?.roomsAndGuests?.adultsCount ? formValues.roomsAndGuests.adultsCount : 0) + (childCount ?? 0);

  /* states */
  const [dialogOpenState, setDialogOpenState] = useState(false);
  const [searchState, setSearchState] = useState('');
  const [onloadInput, setOnloadInput] = useState<string>(isSPO ? propertyName || '' : '');
  const [recentPId, setRecentPid] = useState('');
  const [timerList, setTimerList] = useState<ReturnType<typeof setTimeout>[]>([]);

  //get onloadInput value
  useEffect(() => {
    const urlParamVal = getUrlParam();
    const inputValue = isSPO ? (urlParamVal !== '' ? urlParamVal : propertyName || '') : urlParamVal;
    setOnloadInput(inputValue);
  }, []);

  const fetchSearchResults = (input: string, Uuid: string): void => {
    const searchParams = new URLSearchParams({
      searchTerm: input,
      suggestionSortOrder: 'city,property,airport,poi,state,country',
      latitude: '0',
      longitude: '0',
      uuid: Uuid,
    });
    const searchURL = `${config.playServicePath}/aries-search/v2/autoComplete.comp?${searchParams.toString()}`;
    fetch(searchURL)
      .then(response => response.json())
      .then(response => {
        setRecentPid(response.suggestions[0].placeId); // to set place Id when selected from recent searches or when no option is clicked
      })
      .catch(error => {
        return error;
      });
  };
  const clearTimer = (timers: ReturnType<typeof setTimeout>[]): void => {
    if (timers.length) {
      for (let i = timers.length - 1; i >= 0; i--) {
        clearTimeout(timers[i]);
      }
      setTimerList([]);
    }
  };
  useEffect(() => {
    updateFormValues(onloadInput, recentPId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentPId]);

  const updateFormValues = (val: string, pid?: string): void => {
    setFormValues(
      setDestination({
        displayText: val,
        pid,
        clusterCode: formValues?.destination?.clusterCode ?? '',
      })
    );
  };

  useEffect(() => {
    // Delaying API call till the input value finishes updating
    if (onloadInput) {
      clearTimer(timerList);
      const timer = setTimeout(() => {
        if (UUID) {
          fetchSearchResults(onloadInput, UUID);
        }
      }, 500);
      setTimerList([...timerList, timer]);
    } else {
      // cancel current fetch timeout tiemr if user delete the input value
      clearTimer(timerList);
      // clear options list and show recently viewed when input value is cleared
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const searchFormData = {
      latitude: hiddenFieldsValues?.latitude ?? '',
      longitude: hiddenFieldsValues?.longitude ?? '',
      stateCode: hiddenFieldsValues?.state ?? '',
      countryCode: hiddenFieldsValues?.country ?? '',
      startDate: getMPOFormattedDate(),
      endDate: getMPOFormattedDate(),
      quantity: formValues?.roomsAndGuests?.roomsCount,
      numberInParty: numberInParty,
      childAges: formValues?.roomsAndGuests?.childrenAges,
    };
    if (onloadInput && hiddenFieldsValues?.state) {
      eventUtil.dispatch('DeepLinkUtil', { searchFormData });
      setOnloadInput('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.[DESTINATION_HIDDEN_FIELDS]?.address]);

  /* event handlers */
  // set form form state on dialog open
  const openDialog = (state: string): void => {
    setSearchState(state);
    setDialogOpenState(true);
  };

  return (
    <>
      <div className="mpo-search-title">
        {isMPO && <Text copyText={title} fontSize={Types.size.medium} element={Types.tags.paragraph} />}
      </div>
      <StyledSearchMobile className={`${className} container m-search-container ${previewMode ? 'preview-cta' : ''}`}>
        {!isHideDestinationProps && isMPO ? (
          <button className="search-primary-destination" onClick={(): void => openDialog(DESTINATION_STATE)}>
            {/* for sticky state, show only icon */}
            <span className="icon-location" />
            {/* for non sticky state, show icon and label */}
            <span className="t-font-xs icon-location">{destination}</span>
            <span className="t-subtitle-m">
              {' '}
              {(onloadInput ? onloadInput : destinationText ? destinationText : mobilePlaceholderText) as string}
            </span>
          </button>
        ) : (
          !isHideDestinationProps &&
          !isMPO && (
            <button className="search-primary-destination">
              {/* for sticky state, show only icon */}
              <span className="icon-location" />
              {/* for non sticky state, show icon and label */}
              <span className="t-font-xs icon-location">{destination}</span>
              <span className="t-subtitle-m">{propertyName as string}</span>
            </button>
          )
        )}
        <button
          onClick={(): void => openDialog(CALENDAR_STATE)}
          className={`${isHideDestinationProps ? `no-padding` : ``}`}
        >
          {/* for sticky state, show only icon */}
          <Text copyText={''} customClass={'icon-nav---book'} fontSize={undefined} element={Types.tags.span} />
          {/* for non sticky state, show icon and label */}
          <Text
            copyText={dates}
            customClass={'t-font-xs icon-nav---book'}
            fontSize={undefined}
            element={Types.tags.span}
          />
          <Text
            copyText={displayTextDate}
            customClass={'t-subtitle-inverse-l search-date-label'}
            fontSize={undefined}
            element={Types.tags.span}
          />
        </button>
        {!dialogOpenState ? (
          ''
        ) : (
          <SearchMobileModalLazy
            searchState={searchState}
            setSearchState={setSearchState}
            setDialogOpenState={setDialogOpenState}
            dialogOpenState={dialogOpenState}
            isHideDestinationProps={isHideDestinationProps}
            isPublishUserSelection={isPublishUserSelection}
            isHideFlexibleDates={isHideFlexibleDates}
            isMPO={isMPO}
            isSPO={isSPO}
            acceptLanguage={acceptLanguage}
            colorScheme={colorScheme}
          />
        )}
      </StyledSearchMobile>
    </>
  );
};

export default SearchMobile;
