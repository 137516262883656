import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { StyledForm } from './Form.styles';
import { ContentContext, FormValuesContext } from '../../../index.contexts';
import { FormProps } from './Form.types';
import { CALENDAR_STATE, ROOM_GUEST_STATE, DESTINATION_STATE, SPECIAL_RATES_STATE } from '../../../index.constants';
import HiddenFieldsLazy from '../HiddenFields/HiddenFieldsLazy';
import CommonUtils from '../../../utils/CommonUtils';
import {
  DESTINATION,
  DATES,
  USE_POINTS,
  ROOMS_AND_GUESTS,
  DESTINATION_HIDDEN_FIELDS,
} from '../../../constants/StoreConstants';
import { DateObjectType, getSearchFormattedDate, getValueFromDate } from '../../../utils/DateUtils';
import { showConfirmOnFormSubmit } from '../../../../../lib/helper';
import config from '../../../../../lib/config';
import { eventUtil } from '@marriott/mi-ui-library-shop';
import { DATE_FORMAT_VALUE, RECENT_SEARCH_LIST_LIMIT } from '../../../constants/ApplicationConstants';
import { setDates } from '../../../store/store.actions';
import { recentSearchObj } from '../../../index.types';
import moment from 'moment';
import { addSubDirectoryPrefix } from '../../../../../utils/OfferUtils';
import { INTERNAL_CTA_TYPE } from '../../../../../constants/CommonConstants';

declare module 'react' {
  interface HTMLAttributes<T> {
    // extends React's HTMLAttributes
    custom_click_track_value?: string;
  }
}

const Form: FC<FormProps> = ({
  onBack,
  onFieldClick,
  closePopupWithData,
  isHideDestinationProps = false,
  isPublishUserSelection = false,
  isMPO,
  isSPO,
  acceptLanguage,
}) => {
  const formRef = useRef<HTMLDivElement>(null);
  const { formValues, lastActiveState, setFormValues } = useContext(FormValuesContext);
  const [activeState] = useState(lastActiveState);
  const {
    mobilePlaceholderText,
    ctaLabel,
    localDateFormat,
    roomsGuestsHead,
    room,
    rooms,
    guest,
    guests,
    destination,
    night,
    nights,
    flexibleAlertMessage,
    storefrontPropertySearchWidgetErrorMessageRooms,
    applyLabel,
    id,
    dateFormat,
  } = useContext(ContentContext);
  // const dateFormat = getDateFormat(acceptLanguage ?? '');
  const flexibleLabel = useContext(ContentContext)['flexible'];
  const timestamp = new Date().getTime();
  const destinationText = formValues?.[DESTINATION]?.displayText;
  const { roomsCount = '1', adultsCount = 1, childrenCount = 0 } = formValues?.[ROOMS_AND_GUESTS] ?? {};

  const { searchFormDefaultActionURL, searchFormMeetingURL, searchFormDefaultActionURLSPO } = config;
  const [displayBanner, setDisplayBanner] = useState<boolean>(false);
  // Don't render hidden fields on Server sie rendering.
  const [showHiddenFields, setShowHiddenFields] = useState(false);
  const hiddenFieldsValues = formValues?.[DESTINATION_HIDDEN_FIELDS];

  /**
   * returns the string format of rooms and guest count for display
   * @param roomsCount
   * @param adultsCount
   * @param childrenCount
   */
  const getRoomsAndGuestCount = (roomsCount: string, adultsCount: number, childrenCount: number): string => {
    const guestCount = adultsCount + childrenCount;
    const guestText = guestCount > 1 ? guests : guest;
    const roomsText = roomsCount !== '1' ? rooms : room;
    const roomsCountValue = parseInt(roomsCount, 10);
    if (guestCount > 0 && roomsCountValue < 10) {
      return `${roomsCount} ${roomsText}, ${guestCount} ${guestText}`;
    } else {
      return `${roomsCount} ${roomsText}`;
    }
  };

  /** To make the elements focused after closing the modal */
  useEffect(() => {
    const values = formRef.current?.querySelectorAll('.h-r-form-field-btn') as NodeListOf<HTMLElement>;
    const checkbox = formRef.current?.querySelector('input[type="checkbox"]') as HTMLElement;
    const backIcon = formRef.current?.querySelector('.icon-back-arrow-cropped') as HTMLElement;
    switch (activeState) {
      case DESTINATION_STATE:
        return values[0]?.focus();
      case CALENDAR_STATE:
        return values[1]?.focus();
      case ROOM_GUEST_STATE:
        return values[2]?.focus();
      case SPECIAL_RATES_STATE:
        return values[3]?.focus();
      case USE_POINTS:
        return checkbox?.focus();
      default:
        backIcon?.focus();
    }
  }, [activeState]);

  useEffect(() => {
    // On Client side, render the hidden fields
    setShowHiddenFields(true);
  }, []);

  useEffect(() => {
    setDisplayBanner(roomsCount === '4-9' && isPublishUserSelection ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomsCount]);

  const getLocalizedDate = (date: DateObjectType | undefined): string => {
    moment.locale('locale');
    if (date) {
      //initially date will be in the english format we need to change that to the localized value
      const formattedDate = moment(date).format();
      return moment(formattedDate).format(dateFormat);
    } else {
      return '';
    }
  };
  /** Add dates value computation */
  const { fromDate, toDate, flexible } = formValues?.[DATES] ?? {};
  const numberOfNights = CommonUtils.getNumberOfNights(fromDate, toDate);
  const displayTextDate = `${getLocalizedDate(fromDate)}${toDate ? ` - ${getLocalizedDate(toDate)}` : ''}`;
  const labelTextDate = `${numberOfNights} ${numberOfNights > 1 ? nights : night}${
    flexible ? ` (${flexibleLabel})` : ''
  }`;
  const dateFormatValue = (localDateFormat as string) ?? DATE_FORMAT_VALUE;
  const fromDateValue = getValueFromDate(fromDate, dateFormatValue);
  const toDateValue = getValueFromDate(toDate, dateFormatValue);
  const getRooms = formValues?.roomsAndGuests?.roomsCount;

  const eventDispatch = (): void => {
    closePopupWithData();
    eventUtil.dispatch('searchFormValues', { formValues });
  };

  const eventDispatchMPO = (): void => {
    setFormValues(
      setDates({
        ...(formValues?.[DATES] ?? {}),
        isSelectedDate: true,
      })
    );
    closePopupWithData();
    eventUtil.dispatch('childAgeErrFlag', false);
    eventUtil.dispatch('stayDateMissingErrFlag', false);

    //numberInParty is total number of persons.
    const childCount = formValues?.roomsAndGuests?.childrenCount;
    const numberInParty =
      (formValues?.roomsAndGuests?.adultsCount ? formValues.roomsAndGuests.adultsCount : 0) + (childCount ?? 0);
    const childAges = formValues?.roomsAndGuests?.childrenAges;

    if (!fromDateValue) {
      eventUtil.dispatch('stayDateMissingErrFlag', true);
      return;
    }
    if (childAges?.length) {
      if (childAges?.includes('-1')) {
        eventUtil.dispatch('childAgeErrFlag', true);
        return;
      }
    }
    const searchFormData = {
      latitude: hiddenFieldsValues?.latitude ?? '',
      longitude: hiddenFieldsValues?.longitude ?? '',
      stateCode: hiddenFieldsValues?.state ?? '',
      countryCode: hiddenFieldsValues?.country ?? '',
      startDate: getSearchFormattedDate(fromDateValue),
      endDate: getSearchFormattedDate(toDateValue),
      quantity: formValues?.roomsAndGuests?.roomsCount,
      numberInParty: numberInParty,
      childAges: formValues?.roomsAndGuests?.childrenAges,
    };
    //setting localStorage
    const localSearch = localStorage.getItem('miRecentSearch');
    const recentSearches: recentSearchObj[] = JSON.parse(localSearch ? localSearch : '[]');
    const isDuplicate =
      recentSearches?.length && localStorage.getItem('recentSearchLabel')
        ? recentSearches.find(item => item.label === localStorage.getItem('recentSearchLabel'))
        : true;
    if (!isDuplicate && localStorage.getItem('recentSearchLabel')) {
      const obj = {
        label: localStorage.getItem('recentSearchLabel'),
        state: searchFormData.stateCode,
        country: searchFormData.countryCode,
        value: localStorage.getItem('recentSearchLabel'),
        geocode: `${searchFormData.latitude},${searchFormData.longitude}`,
        placeId: localStorage.getItem('recentPlaceId'),
        latitude: searchFormData.latitude,
        longitude: searchFormData.longitude,
      };

      if (recentSearches && recentSearches.length >= 0) {
        if (recentSearches.length === RECENT_SEARCH_LIST_LIMIT) {
          recentSearches.pop();
        }
        recentSearches.unshift(obj);
      }
      localStorage.setItem('miRecentSearch', JSON.stringify(recentSearches));
    }
    localStorage.removeItem('recentSearchLabel');
    localStorage.removeItem('recentPlaceId');

    eventUtil.dispatch('MPOSearchFormData', { searchFormData });
  };

  const getFormAction = (): string => {
    if (isSPO) {
      return addSubDirectoryPrefix(searchFormDefaultActionURLSPO);
    } else if (getRooms === '26+' && !isSPO) {
      return searchFormMeetingURL;
    } else {
      return searchFormDefaultActionURL;
    }
  };

  return (
    <StyledForm
      action={getFormAction()}
      id={timestamp.toString() + '-find-a-hotel-homePage-form-mobile'}
      noValidate
      method="get"
      autoComplete="off"
      role="search"
      onSubmit={(event: React.SyntheticEvent): void =>
        showConfirmOnFormSubmit(event, roomsCount, flexible, flexibleAlertMessage)
      }
    >
      {showHiddenFields ? <HiddenFieldsLazy acceptLanguage={acceptLanguage} isMPO={isMPO} /> : ''}

      <div className="search-form-body" ref={formRef}>
        <button type="button" className="icon-back-arrow-cropped icon-inverse" onClick={onBack}>
          <span className="sr-only">Back</span>
        </button>

        {!isHideDestinationProps && isMPO ? (
          <button type="button" className="h-r-form-field-btn" onClick={(): void => onFieldClick(DESTINATION_STATE)}>
            <span className="t-overline-inverse-normal icon-location">{destination}</span>
            <span className="t-subtitle-inverse-l">
              {(destinationText ? destinationText : mobilePlaceholderText) as string}
            </span>
          </button>
        ) : (
          !isHideDestinationProps &&
          !isMPO && (
            <button type="button" className="h-r-form-field-btn">
              <span className="t-overline-inverse-normal icon-location">{destination}</span>
              <span className="t-subtitle-inverse-l">
                {(destinationText ? destinationText : mobilePlaceholderText) as string}
              </span>
            </button>
          )
        )}
        <button type="button" className="h-r-form-field-btn" onClick={(): void => onFieldClick(CALENDAR_STATE)}>
          <span className="t-overline-inverse-normal icon-nav---book">{labelTextDate}</span>
          <span className="t-subtitle-inverse-l">{displayTextDate}</span>
        </button>

        <button type="button" className="h-r-form-field-btn" onClick={(): void => onFieldClick(ROOM_GUEST_STATE)}>
          <span className="mi-icon-destination t-overline-inverse-normal">{roomsGuestsHead}</span>
          <span className="t-subtitle-inverse-l icon-arrow-right-cropped">
            {getRoomsAndGuestCount(roomsCount, adultsCount, childrenCount)}
          </span>
        </button>
      </div>
      {displayBanner && (
        <div className="error-banner">
          <div>
            <span className="icon-alert"></span>
          </div>
          <div className="error-banner-message">{storefrontPropertySearchWidgetErrorMessageRooms}</div>
        </div>
      )}
      {!isMPO || getRooms === '10-25' || getRooms === '26+' || getRooms === '4-9' ? (
        <button
          type={isPublishUserSelection && roomsCount !== '10-25' && roomsCount !== '26+' ? `button` : `submit`}
          className="m-button-l m-button-secondary custom_click_track"
          onClick={
            isPublishUserSelection && roomsCount !== '4-9' && roomsCount !== '10-25' && roomsCount !== '26+'
              ? eventDispatch
              : (): void => {
                  // added comment for lint error : empty method
                }
          }
          {...{
            custom_click_track_value: `${id}|${applyLabel}|${INTERNAL_CTA_TYPE}`,
          }}
        >
          {ctaLabel as string}
        </button>
      ) : (
        <button
          type={isPublishUserSelection && roomsCount !== '10-25' && roomsCount !== '26+' ? `button` : `submit`}
          className="m-button-l m-button-secondary custom_click_track"
          onClick={eventDispatchMPO}
          {...{
            custom_click_track_value: `${id}|${applyLabel}|${INTERNAL_CTA_TYPE}`,
          }}
        >
          {ctaLabel as string}
        </button>
      )}
    </StyledForm>
  );
};

export default Form;
